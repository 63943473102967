var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "van-common-detail van-common-detail-button" },
      [
        _c(
          "van-cell-group",
          [
            _c("van-cell", {
              attrs: {
                title: "入库时间：",
                value: _vm.utils.dataString(_vm.appForm.actualInDate)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "仓库名称：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.warehouseName)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "柜号：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.cabinetNo)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "所属合同号（PI/SC）：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.pi)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "国家：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.pickUpInfoVo.country
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "原产地：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.pickUpInfoVo.countryOfOrigin
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "HS编码：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.pickUpInfoVo.hsCode
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "货物名称：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.cargoName)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "箱型：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.pickUpInfoVo.boxType
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "可提件数：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.extractableNumber
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "可提重量（KG）：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.extractableWeight
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "实际剩余件数：",
                value: _vm.utils.isEffectiveCommon(
                  _vm.appForm.actualPieceNumber
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "实际剩余重量（KG）：",
                value: _vm.utils.isEffectiveCommon(_vm.appForm.actualWeight)
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "button-fixed" },
      [
        _c(
          "van-button",
          {
            staticClass: "button-fixed-margin",
            attrs: { type: "info", block: "" },
            on: {
              click: function($event) {
                return _vm.$router.push({
                  path: "/delivery/apply/detailNext",
                  query: { stockContainerId: _vm.stockContainerId }
                })
              }
            }
          },
          [_vm._v(" 查看详情 ")]
        ),
        _c(
          "van-button",
          {
            attrs: { type: "info", block: "" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [_vm._v(" 返回 ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 货物信息 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }