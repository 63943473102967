<template>
  <div>
    <div class="area-title">
      <p class="title">
        货物信息
      </p>
    </div>
    <div class="van-common-detail van-common-detail-button">
      <van-cell-group>
        <van-cell title="入库时间：" :value="utils.dataString(appForm.actualInDate)" />
        <van-cell title="仓库名称：" :value="utils.isEffectiveCommon(appForm.warehouseName)" />
        <van-cell title="柜号：" :value="utils.isEffectiveCommon(appForm.cabinetNo)" />
        <van-cell title="所属合同号（PI/SC）：" :value="utils.isEffectiveCommon(appForm.pi)" />
        <van-cell title="国家：" :value="utils.isEffectiveCommon(appForm.pickUpInfoVo.country)" />
        <van-cell title="原产地：" :value="utils.isEffectiveCommon(appForm.pickUpInfoVo.countryOfOrigin)" />
        <van-cell title="HS编码：" :value="utils.isEffectiveCommon(appForm.pickUpInfoVo.hsCode)" />
        <van-cell title="货物名称：" :value="utils.isEffectiveCommon(appForm.cargoName)" />
        <van-cell title="箱型：" :value="utils.isEffectiveCommon(appForm.pickUpInfoVo.boxType)" />
        <van-cell title="可提件数：" :value="utils.isEffectiveCommon(appForm.extractableNumber)" />
        <van-cell title="可提重量（KG）：" :value="utils.isEffectiveCommon(appForm.extractableWeight)" />
        <van-cell title="实际剩余件数：" :value="utils.isEffectiveCommon(appForm.actualPieceNumber)" />
        <van-cell title="实际剩余重量（KG）：" :value="utils.isEffectiveCommon(appForm.actualWeight)" />
      </van-cell-group>
    </div>
    <div class="button-fixed">
      <van-button type="info" class="button-fixed-margin" block @click="$router.push({path:'/delivery/apply/detailNext',query:{stockContainerId}})">
        查看详情
      </van-button>
      <van-button type="info" block @click="$router.back()">
        返回
      </van-button>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button } from 'vant'

export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button
  },
  data () {
    return {
      stockContainerId: this.$route.query.stockContainerId,
      appForm: {
        pickUpInfoVo: {
          country: '',
          countryOfOrigin: '',
          hsCode: '',
          boxType: ''
        }
      },
      showGoods: false,
      detailList: [],
      finishedText: '没有更多了',
      page: 1,
      tableList: [],
      loading: false,
      finished: false,
      refreshing: false,
      loadingSubmit: false
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail () {
      this.api.delivery.packOutCabinetInfoDetail(this.stockContainerId).then(result => {
        const resData = result.data.value
        if (this._.isEmpty(resData.pickUpInfoVo)) {
          resData.pickUpInfoVo = {
            country: '',
            countryOfOrigin: '',
            hsCode: '',
            boxType: ''
          }
        }
        this.appForm = this._.assign(this.appForm, resData)
      }).catch(() => {
      })
    }
  }
}
</script>

<style>
</style>
